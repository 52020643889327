import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import React from 'react';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TfiAngleLeft, TfiAngleRight } from 'react-icons/tfi';

import 'swiper/css';
import { useRef } from 'react';
import { classNames, roundHalfWithPoint } from '../../utils';
import { OnlineLeistungenCard } from './OnlineLeistungenCard';
import { Rating, Tooltip, Zoom } from '@mui/material';
import { TooltipWrapper } from '../TooltipWrapper';
import { CldImage } from 'next-cloudinary';
import Image from 'next/image';
import { MyLink } from '../MyLink';
import { useSelector } from 'react-redux';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

const OnlineLeistungenCarousel = ({ results, title, link, linkToDetailpage, homepage }) => {
  const swiperRef = useRef();
  const [swiperStartAndEnd, setSwiperStartAndEnd] = useState({
    isBeginning: true,
    isEnd: false,
  });
  const generateSubLink = (category, serviceId) => {
    const encodedCategoryString = encodeURIComponent(category);
    const href = `/online-leistungen/${encodedCategoryString}/id/${serviceId}`;
    return href;
  };
  const bookmarked = useSelector((state) => state.user?.user?.bookmarked);

  return (
    <section className="relative z-50">
      <div className="ml-6 flex items-center pb-6 text-lg md:justify-between">
        <h2 className="text-2xl font-medium md:font-semibold">{title}</h2>
        <div
          className={classNames(homepage && '!hidden', 'hidden items-center justify-end gap-3 text-gray-800 md:flex')}>
          <button
            className="rounded-full border bg-white p-2.5 drop-shadow"
            onClick={() => swiperRef.current.slidePrev()}>
            <TfiAngleLeft
              className={classNames('h-3 w-3 stroke-1', swiperStartAndEnd.isBeginning && '!text-gray-300')}
            />
          </button>
          <button
            className="rounded-full border bg-white p-2.5 drop-shadow"
            onClick={() => swiperRef.current.slideNext()}>
            <TfiAngleRight className={classNames('h-3 w-3 stroke-1', swiperStartAndEnd.isEnd && '!text-gray-300')} />
          </button>
        </div>
      </div>
      <div
        className={classNames(
          !homepage && '!hidden',
          'grid gap-5 px-5 max-lg:grid-cols-2 max-sm:grid-cols-1 lg:grid-cols-12'
        )}>
        {results?.map(({ i, _id, studio, profile, ratings, index, user, images, packages, title, category }) => {
          return (
            <div key={_id} className={i < 3 ? 'md:block lg:col-span-3' : 'md:block lg:col-span-3'}>
              <MyLink
                prefetch={false}
                href={generateSubLink(category?.name || category?.name, _id)}
                target="_blank"
                key={index}
                className="relative">
                {/* Image, bookmark*/}
                <section className="digital-service-carousel relative z-0">
                  {/* Carousel or single */}
                  {images?.length > 1 ? (
                    <Carousel
                      showArrows={true}
                      showThumbs={false}
                      infiniteLoop={true}
                      showStatus={false}
                      renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              onClickHandler();
                            }}
                            title={label}
                            className="arrow-btn absolute left-[15px] top-[calc(50%-15px)] z-50 hidden h-[32px] w-[32px] cursor-pointer">
                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white">
                              <FaAngleLeft className="ml-[-2px] h-6 w-6" />
                            </div>
                          </button>
                        )
                      }
                      renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              onClickHandler();
                            }}
                            title={label}
                            className="arrow-btn absolute right-[15px] top-[calc(50%-15px)] z-10 hidden h-[32px] w-[32px] cursor-pointer">
                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white">
                              <FaAngleRight className="h-6 w-6" />
                            </div>
                          </button>
                        )
                      }>
                      {images.map((imageItem, index) => (
                        <div key={`image_${index}`} className="relative aspect-video w-full">
                          <CldImage
                            key={index}
                            layout="fill"
                            objectFit="cover"
                            width={500}
                            height={320}
                            src={imageItem.publicID}
                            alt={'Online Dienstleistung Bild'}
                            // loading="eager"
                            className="rounded-lg"
                          />
                        </div>
                      ))}
                    </Carousel>
                  ) : (
                    <div className="relative aspect-video w-full">
                      {images.map((imageItem, index) => (
                        <CldImage
                          key={index}
                          layout="fill"
                          objectFit="cover"
                          width={500}
                          height={320}
                          src={imageItem.publicID}
                          alt={'Online Dienstleistung Bild'}
                          // loading="eager"
                          className="h-full w-full max-w-lg rounded-lg object-cover"
                        />
                      ))}
                    </div>
                  )}
                  {/* bookmarking */}
                  {/* z-10 because hearticon in dashboard bookmarks page is infront of header, also in showherosearch button modals */}
                </section>
                {/* below Image */}
                <div className="group">
                  {/* username, image, role*/}
                  <div className="flex items-center space-x-2 py-3">
                    {/* user/profile logo/avatar */}
                    <div>
                      {studio?._id ? (
                        <div className="relative h-[25px] w-[25px] flex-shrink-0">
                          {studio.logo.type !== 'thumbnail' ? (
                            <CldImage
                              src={studio.logo.publicID}
                              width={100}
                              height={100}
                              layout="fill"
                              objectFit="cover"
                              // loading="eager"
                              className="rounded-full bg-gray-200"
                              alt="Studiologo"
                            />
                          ) : (
                            <Image
                              src={'/images/avatar/Avatar-man.png'}
                              width={100}
                              height={100}
                              objectFit="cover"
                              className="h-[40px] w-[40px] rounded-full bg-gray-200"
                              alt="Studiologo"
                            />
                          )}
                        </div>
                      ) : null}
                      {profile?._id ? (
                        <div className="relative h-[25px] w-[25px] flex-shrink-0">
                          {profile.logo?.type !== 'thumbnail' ? (
                            <CldImage
                              src={profile.logo?.publicID}
                              width={100}
                              height={100}
                              objectFit="cover"
                              layout="fill"
                              // loading="eager"
                              className="rounded-full bg-gray-200"
                              alt="Anderes Profil Logo"
                            />
                          ) : (
                            <Image
                              src={'/images/avatar/Avatar-man.png'}
                              width={100}
                              height={100}
                              objectFit="cover"
                              className="rounded-full bg-gray-200"
                              alt="Anderes Profil Logo"
                            />
                          )}
                        </div>
                      ) : null}
                    </div>
                    {/* username s*/}
                    <div className="relative flex min-w-0 flex-1 items-center gap-1 text-xs">
                      {studio?._id ? <p className="truncate font-semibold text-black">{studio?.studioName}</p> : null}
                      {profile?._id ? <p className="truncate font-semibold text-black">{profile?.name}</p> : null}
                      {user?.userProviderSubscription?.id ? (
                        <div className="">
                          <Tooltip
                            title={<p className="whitespace-nowrap text-sm">Verifizierter Anbieter</p>}
                            placement={'top'}
                            className="text-xl"
                            arrow
                            enterTouchDelay={0}
                            leaveTouchDelay={2500}
                            TransitionComponent={Zoom}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  display: { xs: 'none', sm: 'block' },
                                  bgcolor: 'grey.800',
                                  '& .MuiTooltip-arrow': {
                                    color: 'grey.800',
                                  },
                                },
                              },
                            }}
                            PopperProps={{
                              disablePortal: true,
                              popperOptions: {
                                positionFixed: true,
                                modifiers: {
                                  preventoverflow: {
                                    enabled: true,
                                  },
                                },
                              },
                            }}>
                            <TooltipWrapper>
                              <span className="h-3.5 w-3.5 items-start justify-center rounded-full bg-white text-sm font-semibold text-green-600">
                                <svg
                                  className="h-3.5 w-3.5"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 20 20">
                                  <path
                                    fill="currentColor"
                                    d="m18.774 8.245-.892-.893a1.5 1.5 0 0 1-.437-1.052V5.036a2.484 2.484 0 0 0-2.48-2.48H13.7a1.5 1.5 0 0 1-1.052-.438l-.893-.892a2.484 2.484 0 0 0-3.51 0l-.893.892a1.5 1.5 0 0 1-1.052.437H5.036a2.484 2.484 0 0 0-2.48 2.481V6.3a1.5 1.5 0 0 1-.438 1.052l-.892.893a2.484 2.484 0 0 0 0 3.51l.892.893a1.5 1.5 0 0 1 .437 1.052v1.264a2.484 2.484 0 0 0 2.481 2.481H6.3a1.5 1.5 0 0 1 1.052.437l.893.892a2.484 2.484 0 0 0 3.51 0l.893-.892a1.5 1.5 0 0 1 1.052-.437h1.264a2.484 2.484 0 0 0 2.481-2.48V13.7a1.5 1.5 0 0 1 .437-1.052l.892-.893a2.484 2.484 0 0 0 0-3.51Z"
                                  />
                                  <path
                                    fill="#fff"
                                    d="M8 13a1 1 0 0 1-.707-.293l-2-2a1 1 0 1 1 1.414-1.414l1.42 1.42 5.318-3.545a1 1 0 0 1 1.11 1.664l-6 4A1 1 0 0 1 8 13Z"
                                  />
                                </svg>
                                <span className="sr-only">Verifizierungsabzeichen</span>
                              </span>
                            </TooltipWrapper>
                          </Tooltip>
                        </div>
                      ) : null}
                    </div>
                    {/* role */}
                    {/* <div className="inline-flex items-center text-sm font-semibold text-gray-900">
                {profile?.role?.name ? profile?.role?.name : studio ? 'Studio' : 'Nutzer'}
              </div> */}
                  </div>
                  {/* title and price */}
                  <div>
                    {/* title */}
                    <h5
                      className="line-clamp-2 cursor-pointer text-lg font-medium tracking-tight underline-offset-1 group-hover:underline"
                      title={title}>
                      {title}
                    </h5>
                    {/* stars */}
                    {ratings && (
                      <div className="my-1.5 flex items-center text-base">
                        <Rating
                          name="half-rating"
                          size="small"
                          sx={{
                            color: 'black',
                          }}
                          readOnly
                          precision={1}
                          value={1}
                          emptyIcon={<></>}
                        />
                        <div className="font-bold text-black">
                          {ratings?.stars ? roundHalfWithPoint(ratings?.stars / ratings?.count) : 0}
                          <span className="font-normal text-gray-400">{` (${ratings?.count})`}</span>
                        </div>
                      </div>
                    )}
                    {/* price */}
                    <div className="flex items-center justify-between">
                      <span className="text-base font-semibold text-gray-900">Ab {packages[0]?.price}&euro;</span>
                    </div>
                  </div>
                </div>
              </MyLink>
            </div>
          );
        })}
      </div>
      <div className={classNames(homepage && '!hidden')}>
        <Swiper
          breakpoints={{
            300: {
              slidesPerView: 1.5,
              spaceBetween: 25,
            },
            742: {
              slidesPerView: 3,
              spaceBetween: 20,
              slidesPerGroup: 3,
            },
            1128: {
              slidesPerView: 4,
              spaceBetween: 20,
              slidesPerGroup: 4,
            },
            1920: {
              slidesPerView: 4,
              spaceBetween: 20,
              slidesPerGroup: 4,
            },
            2400: {
              slidesPerView: 6,
              spaceBetween: 25,
              slidesPerGroup: 6,
            },
          }}
          onSlideChange={(swiper) => {
            setSwiperStartAndEnd({
              isBeginning: swiper.isBeginning,
              isEnd: swiper.isEnd,
            });
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onReachEnd={(swiper) => {
            setSwiperStartAndEnd({
              isBeginning: swiper.isBeginning,
              isEnd: swiper.isEnd,
            });
          }}>
          {results?.map(({ _id, description, images, packages, title, category }) => (
            <SwiperSlide>
              <OnlineLeistungenCard
                id={_id}
                link={linkToDetailpage ? generateSubLink(category?.name, _id) : ''}
                description={description}
                images={images[0]}
                packages={packages[0]}
                title={title}
                category={category}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default OnlineLeistungenCarousel;
